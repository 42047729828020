.SnackbarContainer-top   {
margin-top: 10% !important;
}
.SnackbarContainer-center{
    margin-top: 10% !important;
}
.SnackbarContainer-root{
margin-top: 10% !important;
}
.css-uwcd5u{
   margin-top: 10% !important; 
}

#watermark{
  display: none !important;
}
.product-team {
  --smart-gantt-chart-task-label-color: white !important;
  --smart-gantt-chart-task-label-color-selected: white !important;
}
.gantt-container {
  max-width: 100%; /* Set a maximum width */
  overflow-x: auto; /* Enable horizontal scrolling if necessary */
}

.gantt-chart .smart-gantt-chart-timeline-header {
  /* Set the desired width for the timeline header */
  width: 800px; /* Adjust as needed */
}
@media print {
    #appBarContainer {
      display: none !important;
    }
    #btnAdd{
        display: none !important;
    }
    #btnUpdate{
        display: none;
    }
     #printablediv{
        margin-top:0 !important;
        padding-top: 0 !important;
        overflow-y: hidden !important;
        /* height: auto !important; */
        /* background-color: white !important; */
        background: #fff;
        box-shadow: none;
    position: relative;
    page-break-inside: avoid;
    height:100%;
    }
    
    #printablediv::after {
        page-break-after:avoid;
        overflow: hidden;
      }
      .MuiBackdrop-root {
        display: none !important;
      }
    .labelsForHide{
        display: none !important;
    }
    #flexContainer{
        display: flex !important;
        justify-content: space-around !important;
        align-items: center !important;
        width: 100% !important;
    }
  }
  .MuiAppBar-colorPrimary{
    background-color: rgb(25, 118, 210) !important;
  }



  .scrollable-label-wrapper {
    width: 100%; /* Set the width of the wrapper to your desired value */
  }

  .scrollable-label {
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 8px;
    overflow-y: auto; /* Enable vertical scrollbar when the content overflows */
    max-height: 100px; /* Set the maximum height of the label container to enable scrolling */
  }


  .description-cell {
    max-height: 40px; /* Adjust the maximum height as needed */
    overflow-y: auto;
    white-space: normal;  /* Ensure normal wrapping behavior */
    word-wrap: break-word;  /* Wrap long words to avoid overflow */
    overflow-wrap: break-word;  /* Fallback for older browsers */
  }

  .status-cell {
    font-weight: bold;
    color: red; /* Change this to the desired color, e.g., "red" for red text */
}
p {
  white-space: normal;  /* Ensure normal wrapping behavior */
  word-wrap: break-word;  /* Wrap long words to avoid overflow */
  overflow-wrap: break-word;  /* Fallback for older browsers */
}